<template>
  <div class="page" id="reorganize">
    <el-form class="query-form searchForm"
             @keyup.enter.native="getDataList(1,1)"
             size="small" ref="inputForm" :model="inputForm" label-width="80px">
      <el-form-item label="专家名称" prop="expertName">
        <el-input v-model.trim="inputForm.expertName"
                  placeholder="请输入专家名称(限50字)"
                  maxlength="50" clearable></el-input>
      </el-form-item>
      <el-form-item label="政治面貌" prop="politicalStatus">
        <el-input v-model.trim="inputForm.politicalStatus"
                  placeholder="请输入政治面貌(限50字)"
                  maxlength="50" clearable></el-input>
      </el-form-item>
      <el-form-item label="籍贯" prop="nativePlace">
        <el-input v-model.trim="inputForm.nativePlace"
                  placeholder="请输入籍贯(限50字)"
                  maxlength="50" clearable></el-input>
      </el-form-item>
      <el-form-item label="民族" prop="expertNation">
        <el-input v-model.trim="inputForm.expertNation"
                  placeholder="请输入民族(限50字)"
                  maxlength="50" clearable></el-input>
      </el-form-item>
      <el-form-item label="年龄" prop="minAge">
        <div class="flex_b_c">
          <el-input style="width: 120px" @input="setAge('minAge')" v-model.trim="inputForm.minAge"
                    @blur="setageData()" placeholder="最小年龄" clearable></el-input>
          <div>~</div>
          <el-input style="width: 120px" @input="setAge('maxAge')" v-model.trim="inputForm.maxAge"
                    @blur="setageData()" placeholder="最大年龄" clearable></el-input>
        </div>
      </el-form-item>
      <el-form-item label="联系电话" prop="expertPhone">
        <el-input maxlength="50" @input="inputForm.expertPhone = inputForm.expertPhone.replace(/[^0-9.]/g,'')"
                  v-model.trim="inputForm.expertPhone" placeholder="请输入联系电话" clearable></el-input>
      </el-form-item>
      <el-form-item class="dateBox2" label-width="20px">
        <div class="flex_b_c">
          <div>
            <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">查询</el-button>
            <el-button size="small" @click="resetting()" icon="el-icon-refresh-right">重置</el-button>
          </div>
          <div>
            <el-button v-if="typePage != 'reviewExperts'" type="primary" size="small"
                       v-show="hasPermissionButton('collection:business:appraisalM:add')"
                       icon='el-icon-plus' @click="addData(0)">新增
            </el-button>
            <el-button type="primary" icon="el-icon-circle-check" size="small"
                       v-show="hasPermissionButton(`collection:business:${typePage}:batchAdopt`)"
                       @click="through(1,3)">
              批量通过
            </el-button>
            <el-button type="danger" icon="el-icon-circle-close" size="small"
                       v-show="hasPermissionButton(`collection:business:${typePage}:batchReject`)"
                       @click="through(1,2)">
              批量驳回
            </el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <div class="bg-white">
      <div class="flex_b_c">
        <div v-if="typePage != 'reviewExperts'" class="tabsCls">
          <el-tabs size="small" v-model="selectTab" @tab-click="handleClick">
            <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                         :name="item.value"></el-tab-pane>
          </el-tabs>
        </div>
        <div></div>
        <el-button size="small" v-if="typePage != 'reviewExperts'"
                   v-show="hasPermissionButton(`collection:business:${typePage}:batchExpor`)"
                   @click="exportData(1)">
          <i class="icon-piliangdaochu iconfont buIcon"/>
          批量导出
        </el-button>
      </div>
      <el-table
          :data="dataList"
          size="small"
          v-loading="loading"
          ref="multipleTable"
          :height="typePage == 'reviewExperts' ? 'calc(100vh - 330px)' : 'calc(100vh - 380px)'"
          class="table"
          :row-key="'expertId'"
          :header-cell-style="{background:'#F9DFDF'}"
          @selection-change="selectionChangeHandle"
      >
        <el-table-column :reserve-selection="true" type="selection" width="50"/>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="examineState" label="审核状态" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $dictUtils.getDictLabel("process_state", scope.row.examineState, '-') }}
          </template>
        </el-table-column>
        <el-table-column prop="expertName" label="专家名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="expertPhone" label="联系电话" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.expertPhone | specialCharacter }}
          </template>
        </el-table-column>
        <el-table-column prop="nativePlace" label="籍贯" show-overflow-tooltip></el-table-column>
        <el-table-column prop="politicalStatus" label="政治面貌" show-overflow-tooltip></el-table-column>
        <el-table-column prop="expertAge" label="年龄" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createUserName" label="创建人" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createDate" sortable label="创建时间" width="150px">
          <template slot-scope="scope">
            {{ scope.row.createDate | formatDate }}
          </template>
        </el-table-column>
        <el-table-column prop="updateUserName" label="最后更新人"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="updateDate" sortable label="最后更新时间" width="150px"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.updateDate | formatDate }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="addData(1, scope.row,scope.$index)"
                       v-show="hasPermissionButton(`collection:business:${typePage}:detail`)">
              详情
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="submitTo(scope.row)"
                       v-if="scope.row.examineState == 0"
                       v-show="hasPermissionButton(`collection:business:${typePage}:submit`)">
              提交
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="submitTo(scope.row)"
                       v-if="scope.row.examineState == '2'"
                       v-show="hasPermissionButton(`collection:business:${typePage}:resubmit`)">
              重新提交
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="addData(2, scope.row)"
                       v-if="scope.row.examineState != 1"
                       v-show="hasPermissionButton(`collection:business:${typePage}:edit`)">修改
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="record(scope.row)"
                       v-show="hasPermissionButton(`collection:business:${typePage}:record`)">
              审核记录
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="deleteDate(scope.row)"
                       v-if="scope.row.examineState == '0'"
                       v-show="hasPermissionButton(`collection:business:${typePage}:delete`)">
              删除
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="deleteDate(scope.row)"
                       v-if="scope.row.examineState == '3'"
                       v-show="hasPermissionButton(`collection:business:${typePage}:remove`)">
              移除
            </el-button>

            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="through(2,3,scope.row)"
                       v-show="hasPermissionButton(`collection:business:${typePage}:adopt`)">
              通过
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="through(2,2,scope.row)"
                       v-show="hasPermissionButton(`collection:business:${typePage}:reject`)">
              驳回
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text_center">
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="current"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="size"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <record ref="record"></record>
    <reviewSuggestions ref="suggestions" @updataStatus="getDataList('',1)"></reviewSuggestions>
  </div>
</template>

<script>
import record from "@/views/modules/collection/accounts/module/record.vue";
import validator from "@/utils/validator";
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";

export default {
  name: "exhibition",
  computed: {
    validator() {
      return validator
    }
  },
  components: {reviewSuggestions, record},
  props: {
    typePage: {
      type: String,
      default: 'appraisalM',
    },
  },
  data() {
    return {
      selectTab: '99',
      tabsList: [
        {
          name: '全部',
          value: '99',
        },
        {
          name: '审核通过',
          value: '3',
        },
        {
          name: '审核中',
          value: '1',
        },
        {
          name: '审核驳回',
          value: '2',
        },
        {
          name: '草稿',
          value: '0',
        },
      ],
      inputForm: {
        expertName: '',
        politicalStatus: '',
        nativePlace: '',
        expertNation: '',
        minAge: '',
        maxAge: '',
        expertPhone: '',
      },

      searchRecord: {},
      dataListSelect: [],
      loading: false,
      dataList: [],

      pageNo2: 0,
      current: 1,
      size: 10,
      total: 0,
    }
  },

  mounted() {
    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
    if (listSearch) {
      this.inputForm = listSearch.inputForm
      this.pageNo2 = listSearch.current
      this.size = listSearch.size
      this.selectTab = listSearch.selectTab
    }

    if (this.typePage == 'reviewExperts') {
      this.selectTab = 1
    }
    this.getDataList('', 1)
  },

  methods: {
    getDataList(type, dividePage) {
      if (type == 1) {
        this.current = 1
      }
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
      }
      this.loading = true
      let auditStatus
      if (this.selectTab == 99) {
        auditStatus = ''
      } else {
        auditStatus = this.selectTab
      }

      let minAge, maxAge
      if (this.inputForm.minAge == '' || this.inputForm.maxAge == '') {
        minAge = ''
        maxAge = ''
      } else {
        minAge = this.inputForm.minAge
        maxAge = this.inputForm.maxAge
      }
      this.searchRecord = {
        ...this.inputForm,
        minAge,
        maxAge,
        examineState: auditStatus,
        current: this.pageNo2 ? this.pageNo2 : this.current,
        size: this.size,
      }
      let fechUrl = this.api.collection.appraisalexpertList
      if (this.typePage == 'reviewExperts') {
        fechUrl = this.api.collection.appraisalexpertExamineList
      }
      this.$axios(fechUrl, this.searchRecord, 'get').then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
          let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
          if (listSearch) {
            this.current = listSearch.current
            this.pageNo2 = 0
            sessionStorage.removeItem('listSearch')
          }
          if (this.dataList.length == 0 && this.current > 1) {
            this.current--
            this.getDataList()
          }
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
          })
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    //提交
    submitTo(row) {
      this.$confirm(`您是否确认提交数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.collection.appraisalexpertSubmit + row.expertId, {}, 'put').then(data => {
          if (data && data.status) {
            this.$message.success('提交成功')
            this.getDataList('', 1)
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },

    //num 0新增 1详情 2修改、重新提交 4审核
    addData(num, row, index) {
      if (num == 0) {
        this.$router.push({
          path: '/collection/business/expertDatabase/addAppraisal',
          query: {butType: num},
        })
      } else {
        let listSearch = {
          inputForm: this.inputForm,
          selectTab: this.selectTab,
          current: this.current,
          size: this.size
        }
        sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
        if (this.typePage == 'reviewExperts') {
          num = 4
          this.searchRecord.current = (this.current - 1) * this.size + index + 1
          this.searchRecord.size = 1
          sessionStorage.setItem('registrationSearch', JSON.stringify(this.searchRecord))
        }
        this.$router.push({
          path: '/collection/business/expertDatabase/addAppraisal',
          query: {butType: num, expertId: row.expertId},
        })
      }
    },

    //审核记录
    record(row) {
      this.$refs.record.init(row.expertId, '', 9)
    },

    //num 1多个 2单个  type 2驳回 3通过
    through(num, type, row) {
      let setData = []
      let revdId = 9
      if (num == 1) {
        if (this.dataListSelect.length == 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        this.dataListSelect.forEach(item => {
          this.$set(item, 'id', item.expertId)
        })
        setData = this.dataListSelect
      } else {
        this.$set(row, 'id', row.expertId)
        setData = [row]
      }
      this.$refs.suggestions.init(num, setData, type, revdId, this.api.collection.appraisalexpertExamine)
    },

    deleteDate(row) {
      this.$confirm(`您是否确认${row.examineState == 3 ? '移除' : '删除'}数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.collection.appraisalexpertRemoveById + row.expertId, {}, 'delete').then(data => {
          if (data && data.status) {
            this.$message.success(`${row.examineState == 3 ? '移除' : '删除'}成功`)
            this.getDataList('', 1)
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },

    resetting() {
      this.$refs.inputForm.resetFields();
      this.inputForm.exhibitionType = ''
      this.inputForm.maxAge = ''
      this.getDataList(1, 1)
    },

    exportData() {
      let ids = []
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }

      ids = this.dataListSelect.map(item => {
        if (item) {
          return item.expertId
        }
      })
      this.exportExcel(this.api.collection.appraisalexpertExport, ids, '藏品鉴定专家库管理列表', 'post')
      this.getDataList('', 1)
    },

    setAge(age) {
      this.inputForm[age] = this.inputForm[age].replace(/[^0-9.]/g, '')
      if (this.inputForm[age] > 999) {
        this.inputForm[age] = 999
      }
    },

    setageData() {
      if (this.inputForm.minAge && this.inputForm.maxAge) {
        if (this.inputForm.minAge > this.inputForm.maxAge) {
          let ageMin = this.inputForm.maxAge
          this.inputForm.maxAge = this.inputForm.minAge
          this.inputForm.minAge = ageMin
        }
      }
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    handleClick(tab, event) {
      this.getDataList(1, 1)
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
  }
  ,
}
</script>

<style lang="scss" scoped>
.bg-white {
  overflow-y: auto !important;
}

/*去掉tabs底部的下划线*/
.tabsCls > > > .el-tabs__nav-wrap::after {
  position: static !important;
}

.searchForm {
  .el-form-item--small.el-form-item {
    display: inline-block;
    width: 24.5%;
  }

  .dateBox2.el-form-item--small.el-form-item {
    display: inline-block;
    width: 50%;
  }
}
</style>
